<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Card from '@/Components/UI/Card.vue'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import FollowButton from '@/Components/User/FollowButton.vue'
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import { type PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { DocumentTextIcon } from '@heroicons/vue/24/outline'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'
import { useArticleCount } from '@/Composables/useArticleCount.ts'
import { useFollowerCount } from '@/Composables/useFollowerCount.ts'
import { useFormatUsername } from '@/Composables/useFormatUsername.js'
import { useGetDomainFromUrl } from '@/Composables/useGetDomainFromUrl.js'

const props = defineProps({
    user: Object as PropType<User>,
    actions: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    }
})

const followerCount = useFollowerCount(props.user, 0)
const articleCount = useArticleCount(props.user, 0)

function cleanUrl (url: string) {
    return url.replaceAll('https://', '').replaceAll('www.', '')
}
</script>

<template>
    <div>
        <Card>
            <div class="flex flex-row items-center justify-between gap-4 md:flex-col lg:flex-row">
                <Link
                    :href="useRoute('user.show', user)"
                    class="flex items-center gap-2 group">
                    <div class="w-14 rounded-full bg-white">
                        <UserAvatar
                            :user="user"
                            class="w-full rounded-full ring-1 transition-all ring-primary/10 group-hover:opacity-80" />
                    </div>
                    <div class="flex flex-col">
                        <div class="flex items-center font-semibold transition-colors space-x-2 group-hover:text-primary">
                            <span class="text-sm">{{ user.name }}</span>
                            <VerifiedBadge
                                :user="user"
                                class="w-5" />
                        </div>
                        <div class="-mt-1 text-sm text-gray-500 dark:text-gray-400">
                            {{ useFormatUsername(user) }}
                        </div>
                    </div>
                </Link>
                <FollowButton
                    v-if="actions && !useIsAuthedUser(user)"
                    :icon-only="true"
                    :user />
                <Button
                    v-if="actions && useIsAuthedUser(user) && usePermissions(UserPermission.EDIT_OWN_USER)"
                    :href="useRoute('dashboard.user.edit')"
                    class="flex justify-center">
                    <div class="mx-auto flex items-center space-x-2">
                        <PencilIcon class="w-4" />
                        <span>Edit profile</span>
                    </div>
                </Button>
            </div>
            <div class="mt-4 flex flex-row items-center justify-between gap-2 md:flex-col lg:flex-row">
                <div class="flex items-center gap-4">
                    <div class="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                        <Icon.UserPlus class="h-4 w-4" />
                        <span>{{ followerCount }} {{ usePlural('follower', followerCount) }}</span>
                    </div>
                    <div class="flex items-center text-sm text-gray-500 space-x-1 dark:text-gray-400">
                        <DocumentTextIcon class="h-4 w-4" />
                        <span>{{ articleCount }} {{ usePlural('article', articleCount) }}</span>
                    </div>
                </div>
                <ul class="flex items-center gap-2 text-slate-900 dark:text-white">
                    <li
                        v-for="link in user.metadata.profile_links.slice(0, 3)"
                        :key="link.url">
                        <a
                            v-tooltip="link.label ? `${link.label} (${link.url})` : `${link.url}`"
                            :href="useRoute('link-out', {url :link.url, redirectToken: usePageProps().csrf_token})"
                            itemprop="sameAs"
                            rel="nofollow"
                            class="flex items-center text-sm text-slate-900/75 space-x-2 hover:text-slate-900 dark:text-white/75 dark:hover:text-white"
                            target="_blank">
                            <span class="sr-only">User web link to {{ cleanUrl(link.url) }}</span>
                            <Icon.TwitterX
                                v-if="useGetDomainFromUrl(link.url) === 'twitter.com' || useGetDomainFromUrl(link.url) === 'x.com'"
                                class="w-4"
                            />
                            <Icon.Facebook
                                v-if="useGetDomainFromUrl(link.url) === 'facebook.com'"
                                class="w-4"
                            />
                            <img
                                v-if="['facebook.com', 'twitter.com', 'x.com'].every((social) => useGetDomainFromUrl(link.url) !== social)"
                                :alt="`Webpage icon for ${cleanUrl(link.url)}`"
                                :src="`https://s2.googleusercontent.com/s2/favicons?domain_url=${useGetDomainFromUrl(link.url)}&sz=48`"
                                class="w-4"
                                height="16"
                                width="16">
                        </a>
                    </li>
                </ul>
            </div>
        </Card>
    </div>
</template>
