<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import FollowButton from '@/Components/User/FollowButton.vue'
import { Link } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import { type PropType, type Ref, ref } from 'vue'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'
import { useArticleCount } from '@/Composables/useArticleCount.ts'
import { useFollowerCount } from '@/Composables/useFollowerCount.ts'
import { useFormatUsername } from '@/Composables/useFormatUsername.js'
import { useGetDomainFromUrl } from '@/Composables/useGetDomainFromUrl.js'

const props = defineProps({
    user: Object as PropType<User>,
    isFollowing: Boolean,
    isPrivate: Boolean,
    hyperlink: {
        type: Boolean,
        default: false
    },
    initialFollowersCount: [Number, null],
    initialArticleCount: [Number, null]
})

const followerCount: Ref<number | null> = useFollowerCount(props.user, props.initialFollowersCount)
const articleCount: Ref<number | null> = useArticleCount(props.user, props.initialArticleCount)
const bioClicked = ref<boolean>(false)

function truncate (text: string, length: number): string {
    if (text.length > length) {
        return text.substring(0, length) + '...'
    }

    return text
}

function cleanUrl (url: string): string {
    return url.replaceAll('https://', '').replaceAll('www.', '')
}
</script>

<template>
    <div class="flex flex-col justify-between space-y-4 md:space-y-0 md:flex-row md:items-center">
        <div
            itemtype="http://schema.org/Person"
            class="flex flex-col items-center group space-y-2 xs:space-x-8 xs:space-y-0 xs:flex-row xs:items-start sm:items-center">
            <component
                :is="hyperlink ? Link : 'span'"
                :href="useRoute('user.show', user)">
                <UserAvatar
                    :show-verified="true"
                    :user="user"
                    :class="hyperlink ? ' transition-all group-hover:opacity-80' : ''"
                    class="w-28 rounded-full border-2"
                    size="medium" />
            </component>
            <div class="flex flex-col">
                <div class="flex w-full flex-col items-center xs:items-start sm:flex-row sm:items-center sm:gap-4">
                    <component
                        :is="hyperlink ? Link : 'h1'"
                        :href="hyperlink ? useRoute('user.show', user) : null"
                        :class="hyperlink ? 'hover:text-primary' : ''"
                        class="text-2xl font-semibold font-heading"
                        itemprop="name">
                        {{ user.name }}
                    </component>
                    <component
                        :is="hyperlink ? Link : 'span'"
                        :href="hyperlink ? useRoute('user.show', user) : null"
                        class="text-sm text-inherit">
                        {{ useFormatUsername(user) }}
                    </component>
                    <ul class="flex items-center gap-2 text-inherit my-1.5">
                        <li
                            v-for="link in user.metadata.profile_links"
                            :key="link.url">
                            <a
                                v-tooltip="link.label ? `${link.label} (${link.url})` : `${link.url}`"
                                :href="useRoute('link-out', {url :link.url, redirectToken: usePageProps().csrf_token})"
                                itemprop="sameAs"
                                rel="nofollow"
                                class="flex aspect-square items-center rounded-full border bg-white p-1 text-sm text-slate-900 border-slate-950/20"
                                target="_blank">
                                <span class="sr-only">User web link to {{ useGetDomainFromUrl(link.url) }}</span>
                                <Icon.TwitterX
                                    v-if="useGetDomainFromUrl(link.url) === 'twitter.com' || useGetDomainFromUrl(link.url) === 'x.com'"
                                    class="w-4"
                                />
                                <Icon.Facebook
                                    v-if="useGetDomainFromUrl(link.url) === 'facebook.com'"
                                    class="w-4"
                                />
                                <img
                                    v-if="['facebook.com', 'twitter.com', 'x.com'].every((social) => useGetDomainFromUrl(link.url) !== social)"
                                    :alt="`Webpage icon for ${cleanUrl(link.url)}`"
                                    :src="`https://s2.googleusercontent.com/s2/favicons?domain_url=${useGetDomainFromUrl(link.url)}&sz=48`"
                                    class="w-4"
                                    height="16"
                                    width="16">
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-col items-start space-y-2 lg:space-x-4 lg:space-y-0 lg:flex-row lg:items-center">
                    <div
                        class="mx-auto flex items-center gap-2 text-sm text-inherit xs:mx-[unset]">
                        <span v-if="articleCount === null">
                            <span class="flex w-full gap-2 skeleton-animation min-w-20">
                                <span class="aspect-square h-3 rounded-full bg-slate-950/10 dark:bg-slate-700" />
                                <span class="h-3 w-full rounded-full bg-slate-950/10 dark:bg-slate-700" />
                            </span>
                        </span>
                        <span v-if="articleCount !== null">
                            {{ articleCount }} {{ usePlural('article', articleCount) }}
                        </span>

                        <span class="aspect-square w-1 rounded-full bg-current opacity-75 sm:w-1.5" />

                        <span v-if="followerCount === null">
                            <span class="flex w-full gap-2 skeleton-animation min-w-20">
                                <span class="aspect-square h-3 rounded-full bg-slate-950/10 dark:bg-slate-700" />
                                <span class="h-3 w-full rounded-full bg-slate-950/10 dark:bg-slate-700" />
                            </span>
                        </span>
                        <span v-if="followerCount !== null">
                            {{ followerCount }} {{ usePlural('follower', followerCount) }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="user.bio"
                    class="mt-2 flex items-center">
                    <div
                        v-linkify:options="{target: '_blank', rel: 'nofollow'}"
                        itemprop="description"
                        :class="bioClicked ? '' : 'line-clamp-2'"
                        class="w-full max-w-lg overflow-hidden break-all text-center text-sm linkable xs:text-left"
                        @click="bioClicked = true"
                        v-html="truncate(user.bio, 240)" />
                </div>
            </div>
        </div>
        <div class="w-full md:w-auto">
            <FollowButton
                v-if="!useIsAuthed() || (useIsAuthed() && !useIsAuthedUser(user))"
                :key="'following-' + isFollowing"
                :following="isFollowing"
                :user="user" />

            <Button
                v-if="useIsAuthedUser(user) && usePermissions(UserPermission.EDIT_OWN_USER)"
                :href="useRoute('dashboard.user.edit')"
                class="flex justify-center">
                <div class="mx-auto flex items-center space-x-2">
                    <PencilIcon class="w-4" />
                    <span>Edit profile</span>
                </div>
            </Button>
        </div>
    </div>
</template>
